export const locale = {
  firstName: 'Ramakrishna',
  lastName: 'Katragadda',
  intro: '',
  aboutMe: 'About Me',
  resume: 'Resume',
  contactMe: 'Contact Me',
  whoAmI: 'Who Am I?',
  whenIamCoding: 'When I\'m coding',
  whenIamNotCoding: 'When I\'m not coding',
  whoAmIDesc: 'I\'m a software developer with a knack for building seamless web applications that solve real-world problems. I thrive on crafting clean, intuitive user experiences and turning creative ideas into functional, efficient code.',
  codingDesc: 'When I\'m in my element, you\’ll find me building innovative solutions, debugging with precision, and leaving every piece of code better than I found it. I\’m passionate about writing clean, maintainable, and scalable code that makes a meaningful impact.',
  notCodingDesc: 'I\'m probably console.logging my thoughts on bike rides, mapping over the latest tech trends, lifting heavy in the gym, or cooking up something delicious in the kitchen. I believe in continuous learning and meaningful connections that make life (and code) more rewarding.',
  peopleOftenCallMe: 'People often call me',
  oneOf: "oneOf(['Rama', 'Krishna', 'RK', 'Ramki', 'Kitty'])."
}
