import React from 'react'
import { createUseStyles } from 'react-jss'
import KT from '../images/KT.jpg'

const useStyles = createUseStyles({
  timelineWrapper: {
    backgroundColor: '#2e364a',
    backgroundColor: '#c0c0c0',
    height: '100vh',
    position: 'relative',
  },
  timeline: {
    position: 'absolute',
    margin: '100px auto',
    maxWidth: 1200,
    zIndex: 0,
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 6,
      height: '100%',
      top: 0,
      left: '50%',
      backgroundColor: '#fff',
      animation: '$moveline 6s linear forwards',
      zIndex: -1
    }
  },
  '@keyframes moveline': {
    '0%': {
      height: 0,
    },
    '100%': {
      height: '100%',
    },
  },
  container: {
    padding: [10, 50],
    position: 'relative',
    width: '50%',
    animation: '$movedown 1s linear forwards',
    opacity: 0,
    '&:nth-child(1)': {
      animationDelay: '0s'
    },
    '&:nth-child(2)': {
      animationDelay: '3s'
    },
    '& > img': {
      position: 'absolute',
      width: 40,
      borderRadius: 30,
      right: -20,
      top: 32,
      zIndex: 10
    }
  },
  '@keyframes movedown': {
    '0%': {
      opacity: 1,
      transform: 'translateY(-30px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0px)',
    },
  },
  textBox: {
    position: 'relative',
    backgroundColor: '#fff',
    padding: [20, 30],
    fontSize: '1.2rem',
    borderRadius: 6,
    '& > h2': {
      fontWeight: 600
    },
    '& > small': {
      marginBottom: 15,
      display: 'inline-block',
    }
  },
  leftContainer: {
    left: 0,
  },
  rightContainer: {
    left: '50%',
    '& > img': {
      left: -20
    }
  },
  leftArrow: {
    height: 0,
    width: 0,
    position: 'absolute',
    top: '28px',
    zIndex: 1,
    borderTop: '15px solid transparent',
    borderBottom: '15px solid transparent',
    borderLeft: '15px solid #fff',
    right: '-15px'
  },
  rightArrow: {
    height: 0,
    width: 0,
    position: 'absolute',
    top: '28px',
    zIndex: 1,
    borderTop: '15px solid transparent',
    borderBottom: '15px solid transparent',
    borderRight: '15px solid #fff',
    left: '-15px'
  }
})
const Timeline = () => {
  const classes = useStyles();

  return (
    <div className={classes.timelineWrapper}>
    <div className={classes.timeline}>
        <div className={`${classes.container} ${classes.leftContainer}`}>
        <img src={KT} alt="Company" />
        <div className={classes.textBox}>
          <h1>Bottomline Technologies</h1>
          <small>2022- Present</small>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, quibusdam!</p>
          <span className={classes.leftArrow}></span>
        </div>
      </div>
        <div className={`${classes.container} ${classes.rightContainer}`}>
        <img src={KT} alt="Company" />
        <div className={classes.textBox}>
          <h1>Bottomline Technologies</h1>
          <small>2022- Present</small>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, quibusdam!</p>
          <span className={classes.rightArrow}></span>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Timeline
