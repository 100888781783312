import React, { useCallback } from "react";
import { createUseStyles } from "react-jss";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const useStyles = createUseStyles({
    particlesBackground: {
        height: "100vh",
  },
})

const particlesConfig = {
    background: {
      color: {
        value: '#2E0249',
      },
    },
    style: {
      position: 'absolute',
      width: '100%',
    },
    fpsLimit: 60,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: 'push',
        },
        onHover: {
          enable: true,
          mode: 'repulse',
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 150,
          duration: 0.4,
          speed: 1.5,
        },
      },
    },
    particles: {
      color: {
        value: '#000',
      },
      links: {
        color: '#fff',
        distance: 120,
        enable: false,
        opacity: 0.5,
        width: 1,
      },
      move: {
        direction: 'random',
        enable: true,
        outModes: {
          default: 'out',
        },
        random: true,
        speed: 0.5,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 138,
      },
      opacity: {
        value: 0.8,
      },
      shape: {
        type: ['star', 'circle'],
      },
      size: {
        value: { min: 0.5, max: 2 }
      },
    },
    detectRetina: true,
  };

const ParticlesBackground = () => {
    const classes = useStyles();

    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    return (
        <div className={classes.particlesBackground}>
        <Particles
            id="tsparticles"
            init={particlesInit}
            options={particlesConfig}
        />
        </div>
    );
};

export default ParticlesBackground;
