import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { locale } from './utils';

const useStyles = createUseStyles({
  aboutMe: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 35,
    padding: 15,
    overflow: 'hidden',
    width: '100vw',
  },
  heading: {
    fontSize: 40,
    fontWeight: 'bold',
  },
  tilesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 30,
    alignItems: 'center',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    borderRadius: 12,
    transition: 'all ease-in 1s',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#fff',
    color: '#2E0249',
    padding: 20,
    opacity: 0,
    fontSize: 16,
    backgroundColor: '#faf8ff',
  },
  section1: {
    transform: 'translateX(-30%)',
  },
  section2: {
    transform: 'translateX(30%)',
  },
  section3: {
    transform: 'translateX(-30%)',
  },
  slideIn: {
    transform: 'translateX(0) !important',
    opacity: '1 !important',
  },
  code: {
    backgroundColor: 'rgba(9, 30, 66, 0.04)',
    fontFamily: 'courier, monospace',
    borderRadius: 3,
    padding: [2, 4],
    margin: [0, 4],
    fontSize: 16,
  },
  '@media (min-width: 481px)': {
    section: {
      width: '75%',
      fontSize: '1.4rem',
      lineHeight: '1.5rem',
    },
  },
  '@media (min-width: 768px)': {
    section: {
      width: '75%',
      fontSize: '1.4rem',
      lineHeight: '1.5rem',
    },
    code: {
      fontSize: '1.4rem'
    }
  },
  '@media (min-width: 1024px)': {
    section: {
      width: '65%',
      fontSize: '1.4rem',
      lineHeight: '1.5rem',
    },
  },
  '@media (min-width: 1440px)': {
    section: {
      width: '50%',
      fontSize: '1.4rem',
      lineHeight: '1.5rem',
    },
  },
});


const AboutMe = () => {
  const [isVisible, setIsVisible] = useState({
    section1: false,
    section2: false,
    section3: false,
  });

  const refs = {
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
  };

  const classes = useStyles();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.dataset.id;
          console.log(entry.isIntersecting, id)
          setIsVisible((prev) => ({
            ...prev,
            [id]: entry.isIntersecting,
          }));
        });
      },
      { threshold: 0.1 }
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) observer.observe(ref.current);
    });

    return () => {
      Object.values(refs).forEach((ref) => {
        if (ref.current) observer.unobserve(ref.current);
      });
    };
  }, []);

  return (
    <section className={classes.aboutMe}>
      <h2 className={classes.heading}>About Me</h2>
      <div className={classes.tilesContainer}>
        <div
          className={`${classes.section} ${classes.section1} ${isVisible.section1 ? classes.slideIn : ''}`}
          ref={refs.section1}
          data-id="section1"
        >
          <h3>{locale.whoAmI}</h3>
          <p>
            <span>{locale.peopleOftenCallMe}</span>
            <code className={classes.code}>{locale.oneOf}</code>
            <span>{locale.whoAmIDesc}</span>
          </p>
        </div>

        <div
          className={`${classes.section} ${classes.section2} ${isVisible.section2 ? classes.slideIn : ''}`}
          ref={refs.section2}
          data-id="section2"
        >
          <h3>{locale.whenIamCoding}</h3>
          <p>{locale.codingDesc}</p>
        </div>

        <div
          className={`${classes.section} ${classes.section3} ${isVisible.section3 ? classes.slideIn : ''}`}
          ref={refs.section3}
          data-id="section3"
        >
          <h3>{locale.whenIamNotCoding}</h3>
          <p>{locale.notCodingDesc}</p>
        </div>
        </div>
    </section>
  );
};

export default AboutMe;
