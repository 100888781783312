import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss';
import Typed from "typed.js";

const useStyles = createUseStyles({
  heroSection: {
    position: 'relative',
    userSelect: 'none',
    color: '#fff',
    fontFamily: 'Monaco',
  },
  name: {
    fontSize: '2.4rem',
  },
  intro: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    marginTop: '0.7rem',
    fontSize: '1rem',
  },
  '@media (min-width: 481px)': {
    heroSection: {
      position: 'absolute',
      top: '40%',
      left: '10%',
    },
    name: {
      fontSize: '2.8rem',
    },
    intro: {
      fontSize: '1.2rem',
    }
  },
  '@media (min-width: 768px)': {
    heroSection: {
      position: 'absolute',
      top: '40%',
      left: '10%',
    },
    name: {
      fontSize: '3.2rem',
    },
    intro: {
      fontSize: '1.6rem',
    }
  },
  '@media (min-width: 1024px)': {
    name: {
      fontSize: '3.6rem',
    },
    intro: {
      fontSize: '2rem',
    }
  },
  '@media (min-width: 1440px)': {
  },
});

const options = {
  strings: [
    "Rama,",  // English
    "రామ,",  // Telugu
    "ராம,",   // Tamil
    "ರಾಮ,",  // Kannada
    "राम,",    // Hindi
    "രാമ,",   // Malayalam
    "রাম,",   // Bengali
  ],
  typeSpeed: 200,
  backSpeed: 100,
  backDelay: 1000,
  loop: true,
};

const TypingEffect = () => {
  const classes = useStyles();
  const el = React.useRef(null);
  const typed = React.useRef(null);


  useEffect(() => {
    typed.current = new Typed(el.current, options);
    return () => {
      typed.current.destroy();
    }
  }, []);

  return (
    <div className={classes.heroSection}>
      <h1 className={classes.name}>
        <span>Ramakrishna</span>
        <br />
        <span>Katragadda.</span>
      </h1>
      <p className={classes.intro}>
        <span> Hi, I'm </span>
        <span ref={el} className={classes.dynamicName} />
        <span>nice to meet you!</span>
      </p>
    </div>
  )
}

export default TypingEffect;
