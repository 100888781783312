import React from 'react'
import ParticlesBackground from './ParticlesBackground'
import TypingEffect from './TypingEffect'
import { createUseStyles } from 'react-jss'
import Resume from '../images/Resume.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

const useStyles = createUseStyles({
  home: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    fontFamily: 'Monaco',
    height: '100vh',
    userSelect: 'none',
  },
  button: {
    zIndex: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
    backgroundColor: 'transparent',
    border: '1px solid #fff',
    borderRadius: 30,
    outline: 'none',
    cursor: 'pointer',
    margin: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& svg': {
      marginRight: 6,
    },
    "&:hover": {
      transition: 'ease-in-out 300ms'
    },
  },
  anchor: {
    outline: 'none',
    fontSize: '1rem',
    color: 'whitesmoke',
    textDecoration: 'none',
    '&:hover': {
      color: '#F806CC',
    }
  },
  '@media (min-width: 481px)': {
    anchor: {
      fontSize: '1rem',
    },
  },
  '@media (min-width: 768px)': {
    anchor: {
      fontSize: '1rem',
    },
  },
  '@media (min-width: 1024px)': {
    anchor: {
      fontSize: '1.1rem',
    },
  },
  '@media (min-width: 1440px)': {
    anchor: {
      fontSize: '1.2rem',
    },
  },
  scrollDown: {
    width: 40,
    height: 70,
    borderRadius: 18,
    position: 'relative',
    backgroundColor: '#fff',
    border: 'none',
    outline: 'none',
    width: '100%',
    height: '100%',
    backgroundColor: '#c9d6df',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollDownOuter: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  scrollDownInner: {
    width: '50px',
    height: '90px',
    border: '3px solid #F806CC',
    borderRadius: '60px',
    position: 'relative',
    '&::before': {
      content: '""',
      width: '12px',
      height: '12px',
      position: 'absolute',
      top: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#fff',
      borderRadius: '50%',
      opacity: 1,
      animation: '$wheel 2s infinite',
    },
  },
  '@keyframes wheel': {
    to: {
      opacity: 0,
      top: '60px',
    },
  },
})
const Home = ({ handleClick }) => {
  const classes = useStyles();


  return (
    <div className={classes.home}>
      <button className={classes.button}>
        <a href={Resume} download="Ramakrishna_Resume.pdf" className={classes.anchor}>
          <FontAwesomeIcon icon={faFileAlt} />
          Resume
        </a>
      </button>
      <ParticlesBackground />

      <TypingEffect />


        <button className={classes.scrollDownOuter} onClick={handleClick}>
         <div className={classes.scrollDownInner}/>
        </button>
      </div>
  )
}

export default Home
