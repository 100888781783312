import React, { useState } from 'react';
import { createUseStyles, ThemeProvider } from 'react-jss';

import Home from './Home';
import Projects from './Projects';
import Technologies from './Technologies';
import ParticlesBackground from './ParticlesBackground';
import Timeline from './Timeline';
import AboutMe from './AboutMe';

// '@media (min-width: 481px)': {
// },
// '@media (min-width: 768px)': {
// },
// '@media (min-width: 1024px)': {
// },
// '@media (min-width: 1440px)': {
// },
function App() {

  const aboutMeRef = React.useRef(null);

  const handleClick = () => {
    aboutMeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Home handleClick={handleClick}/>
      <div ref={aboutMeRef} style={{ height: '100vh' }}>
        <AboutMe />
      </div>
      {/* <Projects /> */}
      {/* <Technologies /> */}
      {/* <Timeline/> */}
    </>
  );
}

export default App;
